import React from "react";
import Countdown from "react-countdown";
import { Typography, makeStyles } from "@material-ui/core";
import localColors from "../util/Colors";

const useStyles = makeStyles((theme) => ({
  counter: {
    // paddingTop: "30px",
    padding: theme.spacing(2),
    textAlign: "center",
    color: localColors.textColor,
  },
}));

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Typography variant="h4">We tied the Knot!</Typography>;
  } else {
    return (
      <div>
        <Typography
          style={{
            fontFamily: "Snell",
            color: localColors.rawColor.goldenRod,
            textAlign: "center",
          }}
          variant="h1"
        >
          {days}
        </Typography>
        <Typography
          style={{
            fontFamily: "Snell",
            color: localColors.rawColor.darkRedOverlay
          }}
          variant="h2"
        >
          days to go!
        </Typography>
      </div>
    );
  }
};

export default function CountDown() {
  const style = useStyles();
  return (
    <div>
      <div className={style.counter}>
        <Countdown date="2020-10-13" precision={3} renderer={renderer} />
      </div>
    </div>
  );
}
