import React from "react";
import { Centered } from "../util/Styled";
import Typography from "@material-ui/core/Typography";

const Game = () => {
  return (
    <Centered>
      <Typography
        variant="h2"
        style={{
          paddingTop: "20px",
          textAlign: "center",
          flexGrow: 1,
          color: "white",
        }}
      >
        The Mines of Mawage
      </Typography>
      <iframe
        style={{ zIndex: "2000" }}
        src="/govith.html"
        width="900"
        frameBorder="0"
        height="576"
      >
        Loading...
      </iframe>
    </Centered>
  );
};

export default Game;
