import React from "react";
import { makeStyles } from "@material-ui/core";
import localColors from "../util/Colors";

const useStyles = makeStyles((theme) => ({
  footer: {
    right: 0,
    bottom: 0,
    width: "100%",
    background: localColors.rawColor.darkRedOverlay,
    position: "absolute",
  },
  signature: {
    float: "right",
    padding: 0,
    margin: 0,
    color: localColors.textColor,
    paddingRight: "30px",
  },
}));

export default function Footer() {
  const styles = useStyles();
  return (
    <div style={{ height: "40px" }}>
      <div className={styles.footer}>
        <p className={styles.signature}>
          Designed and Written with 🤍 by Liz and Pat
        </p>
      </div>
    </div>
  );
}
