import React from "react";
import Gallery from "./Gallery";
import RSVPForm from "./RSVPForm";
import Bulbasaur from "./Bulbasaur";
import Logistics from "./Logistics";
import WeddingParty from "./WeddingParty";
import Homepage from "./Homepage";
import OurStory from "./OurStory";
import Game from "./Game";
import Registry from "./Registry";
import { getEngagementPhotos } from "../data/DAO";

export const pages = {
  homepage: "homepage",
  weddingParty: "wedding party",
  logistics: "logistics",
  gallery: "gallery",
  rsvp: "rsvp",
  secret: "secret",
  registry: "registry",
  our_story: "our_story",
};

export default class PageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: "homepage" };
    window.pageContent = this;
  }

  pageContent() {
    switch (this.state.page) {
      case pages.homepage:
        return <Homepage />;
      case pages.logistics:
        return <Logistics />;
      case pages.weddingParty:
        return <WeddingParty />;
      case pages.gallery:
        return <Gallery photoset={getEngagementPhotos()} bulbasaur={true} />;
      case pages.rsvp:
        return (
          <div style={{ paddingTop: "20px" }}>
            <RSVPForm />;
          </div>
        );
      case pages.secret:
        return <Bulbasaur />;
      case pages.game:
        return <Game />;
      case pages.our_story:
        return <OurStory />;
      case pages.registry:
        return <Registry />;
      default:
        return <p style={{ textAlign: "center" }}>No Content Here!</p>;
    }
  }

  render() {
    return (
      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        {this.pageContent()}
      </div>
    );
  }

  changePage(pageName) {
    this.setState({
      page: pageName,
    });
  }
}
