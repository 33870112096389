// import "../App.css";
import { Grid } from "@material-ui/core";
import React from "react";
import Bulbasaur from "./Bulbasaur";
import { Centered } from "../util/Styled";
import ModalImage from "./ModalImage";

const getPhotos = (photoList) => {
  return photoList
    .sort(() => Math.random() - 0.5)
    .map((it) => {
      return (
        <ModalImage key={it.thumb} thumb={it.thumb} src={it.img}></ModalImage>
      );
    });
};
export default function Gallery(props) {
  const { photoset, bulbasaur } = props;
  const maybeBulbasaur = () => {
    if (bulbasaur) {
      return <Bulbasaur />;
    }
  };
  return (
    <div>
      <Centered>
        <Grid
          container
          style={{
            maxWidth: "1000px",
            width: "80%",
          }}
          spacing={0}
        >
          {getPhotos(photoset)}
        </Grid>
      </Centered>
      {maybeBulbasaur()}
    </div>
  );
}
