import spans from "../util/LocalSpans";
import "../App.css";
import { Grid } from "@material-ui/core";
import React from "react";

const SinglePhoto = (props) => {
  const { photo } = props;
  return (
    <Grid
      style={{
        padding: "20px",
      }}
      item
      xs={spans.halfwidth}
      sm={spans.thirdwidth}
      md={spans.quarterwidth}
    >
      <img
        style={{
          maxWidth: "200px",
          width: "100%",
          height: "100%",
          maxHeight: "200px",
        }}
        src={photo}
      />
    </Grid>
  );
};

export default SinglePhoto;
