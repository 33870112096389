import React from "react";
import CountDown from "./CountDown";
import styled from "styled-components";

// waterman clickable
// fix border
export default function Logistics() {
  const Center = styled.div`
    text-align: center;
  `;
  const Over = styled.div`
    padding-left: 20px;
  `;
  const Title = styled.div`
    font-family: Snell;
    font-size: xx-large;
    margin-top: 50px;
    color: #deb534;
  `;
  const SubTitle = styled.p`
    font-size: larger;
    font-weight: bold;
  `;
  const Cell = styled.td`
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    color: white;
  `;
  const ColoredAnchor = styled.a`
    color: white;
    text-decoration: none;

    &:hover {
      color: white;
      text-decoration: underline;
      cursor: pointer;
    }
  `;

  return (
    <div>
      <CountDown />;{/* <LocationComponent />; */}
      <Over>
        <Over>
          <Title style={{ marginTop: "10px" }}>Saturday, October 10th</Title>
          <Over>
            <SubTitle>Early Bird Barbecue</SubTitle>
            <Over>
              <p>
                Early arrivers for the Costey-Beagan Wedding are invited to join
                Liz, Pat, friends, & family for a casual Barbecue the night
                before the wedding. Expect games, delicious BBQ, and probably
                some embarassing stories. See y'all there!
              </p>
              <p>* Separate invitations will be sent to attending RSVPs</p>
              <SubTitle>Location:</SubTitle>
              <Over>
                <p>To Be Announced</p>
              </Over>
              <SubTitle>Schedule:</SubTitle>
              <Over>
                <p>To Be Announced</p>
              </Over>
            </Over>
          </Over>
          <Title>Sunday, October 11th</Title>
          <Over>
            <p>Wedding Ceremony and Reception</p>
            <Over>
              <SubTitle>Location:</SubTitle>
              <Over>
                <ColoredAnchor href="https://www.watermangrille.com/">
                  <p style={{ marginBottom: "0px" }}>Waterman Grille</p>
                </ColoredAnchor>
                <Over>
                  <ColoredAnchor href="https://goo.gl/maps/WGUhPHSUF8SH5gQP6">
                    <p style={{ marginTop: "0px", fontSize: "smaller" }}>
                      4 Richmond Square, <br />
                      Providence, RI 02906
                    </p>
                  </ColoredAnchor>{" "}
                </Over>
              </Over>
              <SubTitle style={{ marginBottom: "0px" }}>Schedule:</SubTitle>
              <Over>
                <table>
                  <tr>
                    <Cell>10:00am</Cell>
                    <Cell>Guest Arrival</Cell>
                  </tr>
                </table>
                <tr>
                  <Cell>10:30am</Cell>
                  <Cell>Ceremony Begins</Cell>
                </tr>
                <tr>
                  <Cell>11:00am</Cell>
                  <Cell>Ceremony Concludes</Cell>
                </tr>
                <tr>
                  <Cell>11:45am</Cell>
                  <Cell>Luncheon Begins</Cell>
                </tr>
                <tr>
                  <Cell>2:00pm</Cell>
                  <Cell>Cake Cutting</Cell>
                </tr>
                <tr>
                  <Cell>2:45pm</Cell>
                  <Cell>Family & Group Photos</Cell>
                </tr>
                <tr>
                  <Cell>3:30pm</Cell>
                  <Cell>Event Concludes</Cell>
                </tr>
              </Over>
            </Over>
          </Over>
          <Title style={{ paddingTop: "10px" }}>Monday, October 12th</Title>
          <Over>
            <SubTitle>Farewell Breakfast</SubTitle>
            <Over>
              <p>
                For guests leaving Monday, join us for breakfast and a sleepy
                farewell from the bride and groom. <br /> Come and go as you
                please!
              </p>
              <Over>
                <SubTitle>Location:</SubTitle>
                <Over>
                  <p>To Be Announced</p>
                  {/* <ColoredAnchor href="https://www.colblackintoninn.com/">
                  The Blackinton Inn
                </ColoredAnchor>
                <br />
                <ColoredAnchor href="https://www.google.com/maps?q=Blakinton+inn+address&um=1&ie=UTF-8&sa=X&ved=2ahUKEwip99zB3-vqAhUERK0KHYK3CWQQ_AUoAXoECBwQAw">
                  203 N Main St, Attleboro, MA 02703
                </ColoredAnchor> */}
                </Over>
                <SubTitle>Schedule:</SubTitle>
                <Over>
                  <p>To Be Announced</p>
                </Over>
              </Over>
            </Over>
          </Over>
        </Over>
      </Over>
    </div>
  );
}
