import React from "react";
import { Centered } from "../util/Styled";
import { Button } from "@material-ui/core";
import pages from "./PageContent";
import localColors from "../util/Colors";

const OurStory = (props) => {
  return (
    <Centered>
      <div style={{ width: "50%", textAlign: "start" }}>
        <p>
          Pat and Liz met at UMass Amherst in 2016, and quickly bonded over
          their love of books, travel, & games. They had their first date in a
          bookstore - the same bookstore where they were engaged, nearly 4 years later.
        </p>
        <p>
          They couldn't think of anything else clever to write, so they wrote a
          game instead. Warning, doesn't work on phones! Best of luck, & have fun!
        </p>
        <p>
          Welcome to the mines of Mawage. There are treasures to be found
          throughout these caves, but stay alert! Here there be monsters.
        </p>
        <Button
          style={{ textAlign: "center" }}
          variant="contained"
          color={localColors.rawColor.darkRed}
          disableElevation
          onClick={() => {
            window.pageContent.changePage(pages.game);
          }}
        >
          Our Story
        </Button>
      </div>
    </Centered>
  );
};
export default OurStory;
