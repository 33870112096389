import "../App.css";
import { Grid } from "@material-ui/core";
import React from "react";
import SinglePhoto from "./Photo";
import { getHomepageData } from "../data/DAO";
import TextBox, { TextBoxInner } from "./TextBox";
import { SizeMe } from "react-sizeme";
import { Centered } from "../util/Styled";
import spans from "../util/LocalSpans";

const HomepageItemWrapper = ({ inner }) => {
  return (
    <Grid
      style={{
        padding: "20px",
        textAlign: "-webkit-center",
      }}
      item
      xs={spans.halfwidth}
      sm={spans.thirdwidth}
    >
      {inner}
    </Grid>
  );
};

const getHomepageItems = (data) => {
  return data.map((it) => {
    if (it.img !== undefined) {
      return (
        <HomepageItemWrapper
          inner={
            <img
              style={{
                maxWidth: "200px",
                width: "100%",
                height: "100%",
                maxHeight: "200px",
              }}
              src={it.img}
            />
          }
        />
      );
    }
    if (it.text !== undefined) {
      return <HomepageItemWrapper inner={<TextBoxInner text={it.text} />} />;
    }
    return null;
  });
};

function alternatingHomepageData() {
  var z = getHomepageData().slice(0, 6);
  var a = z.filter((x) => x.img !== undefined);
  var b = z.filter((x) => x.text !== undefined);
  var c = a.map((e, i) => [e, b[i]]).flatMap((x) => x);
  return c;
}

export default function Homepage() {
  return (
    <Centered>
      <SizeMe>
        {({ size }) => {
          const data =
            size.width < 960 ? alternatingHomepageData() : getHomepageData();
          return (
            <div>
              <Grid
                container
                style={{
                  maxWidth: "1000px",
                  width: "80%",
                }}
                spacing={0}
              >
                {getHomepageItems(data)}
              </Grid>
            </div>
          );
        }}
      </SizeMe>
    </Centered>
  );
}
