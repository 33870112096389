import React from "react";
import styled from "styled-components";
import SinglePhoto from "./Photo";
import { Grid } from "@material-ui/core";
import spans from "../util/LocalSpans";
import { Centered } from "../util/Styled";

export default function Registry(params) {
  const LeftAligned = styled.div`
    margin-left: 20px;
    margin-right: 40px;
  `;
  const Bordered = styled.div`
    border: 5px solid #663544;
    padding: 10px;
    margin-left: 20px;
    margin-right: 40px;
  `;
  const Box = styled.div``;
  return (
    <div>
      <Grid container spacing={0}>
        <Grid
          style={{
            padding: "20px",
          }}
          item
          sm={spans.fullwidth}
          md={spans.halfwidth}
        >
          <LeftAligned>
            <p
              style={{
                fontSize: "x-large",
                margin: "10px",
              }}
            >
              Welcome to our
            </p>
            <p
              style={{
                fontSize: "xx-large",
                margin: "10px",
                marginBottom: "20px",
              }}
            >
              Registry!
            </p>
          </LeftAligned>
          <Bordered>
            <div>
              <p>
                Please, please note - we do NOT expect gifts or contributions.
                If you would really like to treat us, please feel free to check
                out our Registry Wish Lists.{" "}
              </p>
              <p>
                Either way, thank you for your thoughtfulness & generosity. We
                can't wait to see you in October!
              </p>
            </div>
          </Bordered>
        </Grid>

        <Grid item sm={spans.fullwidth} md={spans.halfwidth}>
          <Centered>
            <div style={{ width: "80%" }}>
              <Grid container spacing={1} style={{ padding: "20px" }}>
                {[
                  { img: "/Target-v2.png", link: "http://tgt.gifts/Liz+Pat" },
                  {
                    img: "/Crate-Barrel.png",
                    link:
                      "https://www.crateandbarrel.com/gift-registry/elizabith-costey-and-patrick-beagan/r6148650",
                  },
                ].map((it) => {
                  return (
                    <Grid item xs={spans.fullwidth} sm={spans.halfwidth}>
                      <a href={it.link}>
                        <img
                          style={{
                            width: "95%",
                            height: "auto",
                            maxWidth: "250px",
                            minWidth: "100px",
                            padding: "10px",
                          }}
                          src={it.img}
                        />
                      </a>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </Centered>
        </Grid>
      </Grid>
    </div>
  );
}
