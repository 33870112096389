import "../App.css";
import { Grid } from "@material-ui/core";
import React from "react";
import spans from "../util/LocalSpans";
import localColors from "../util/Colors";

export const TextBoxInner = (props) => {
  const { text } = props;

  return (
    <div
      style={{
        backgroundColor: localColors.rawColor.darkRedOverlay,
        width: "100%",
        height: "100%",
        position: "relative",
        textAlign: "center",
        maxHeight: "200px",
        maxWidth: "200px",
        color: localColors.rawColor.white,
      }}
    >
      <div className={"vertical-center"} style={{ width: "100%" }}>
        {text.split("\n").map((item, i) => {
          return (
            <p style={{ margin: 0, padding: 0, fontSize: "150%" }} key={i}>
              {item}
            </p>
          );
        })}
      </div>
    </div>
  );
};
const TextBox = (props) => {
  return (
    <Grid
      style={{
        padding: "20px",
        textAlign: "-webkit-center",
      }}
      item
      xs={spans.halfwidth}
      sm={spans.thirdwidth}
      md={spans.quarterwidth}
    >
      <TextBoxInner text={props.text} />
    </Grid>
  );
};
export default TextBox;
