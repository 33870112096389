import React from "react";
import { SizeMe } from "react-sizeme";
import { getGroomsPeople } from "../data/DAO";
import GroomsPerson from "./GroomsPerson";
import GroomsPersonMobile from "./GroomsPersonMobile";

export default function WeddingParty() {
  return getGroomsPeople().map(SingleGroomsPerson);
}

function SingleGroomsPerson(input) {
  const { src, title, body, isGroomsMan } = input;
  return (
    <SizeMe>
      {({ size }) => {
        return size.width < 600 ? (
          <GroomsPersonMobile
            src={src}
            title={title}
            body={body}
            isGroomsMan={isGroomsMan}
          />
        ) : (
          <GroomsPerson
            src={src}
            title={title}
            body={body}
            isGroomsMan={isGroomsMan}
          />
        );
      }}
    </SizeMe>
  );
}
