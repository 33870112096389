import React from "react";
import localColors from "../util/Colors";
import { makeStyles, Typography } from "@material-ui/core";

const rawStyle = (isGroomsMan) =>
  makeStyles((theme) => {
    return {
      box: {
        maxWidth: "800px",
        float: "center",
        width: "80%",
        display: "inline",
        float: "none",
        margin: "0 auto",
        display: "flow-root",
      },
      img: {
        borderColor: localColors.rawColor.white,
        borderRadius: "50%",
        borderStyle: "solid",
        borderWidth: "thick",
        float: "left",
        height: "120px",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        margin: "5px",
        width: "120px",
      },
      textBox: {
        background: localColors.rawColor.white,
        borderRadius: "20px",
        background: isGroomsMan
          ? localColors.backgroundGroomsMan
          : localColors.backgroundBridesMaid,
        display: "block",
        minHeight: "110px",
        margin: "10px",
        marginLeft: "50px",
      },
      textContainer: {
        paddingLeft: "100px",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingRight: "10px",
      },
      title: {
        color: isGroomsMan
          ? localColors.fontColorGroomsMan
          : localColors.fontColorBridesMaid,
      },
      body: {
        color: isGroomsMan
          ? localColors.fontColorGroomsMan
          : localColors.fontColorBridesMaid,
      },
    };
  });

export default function GroomsPerson({ src, title, body, isGroomsMan }) {
  const styleClass = rawStyle(isGroomsMan)();
  return (
    <div className={styleClass.box}>
      <img className={styleClass.img} src={src} />
      <div className={styleClass.textBox}>
        <div className={styleClass.textContainer}>
          <Typography variant="h5" className={styleClass.title}>
            {title}
          </Typography>
          <p className={styleClass.body}>{body}</p>
        </div>
      </div>
    </div>
  );
}
