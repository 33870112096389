import React from "react";
import localColors from "../util/Colors";
import { makeStyles, Typography } from "@material-ui/core";

const rawStyle = (isGroomsMan) =>
  makeStyles((theme) => {
    return {
      box: {
        display: "flow-root",
        margin: "0 auto",
        paddingTop: "100px",
        maxWidth: "800px",
        width: "80%",
      },
      img: {
        borderColor: localColors.rawColor.white,
        borderRadius: "50%",
        borderStyle: "solid",
        borderWidth: "thick",
        boxShadow:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        height: "140px",
        position: "absolute",
        width: "140px",
        zIndex: "10",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      textBox: {
        background: isGroomsMan
          ? localColors.backgroundGroomsMan
          : localColors.backgroundBridesMaid,
        borderRadius: "20px",
        display: "block",
        minHeight: "110px",
        textAlign: "initial",
      },
      textContainer: {
        paddingLeft: "10px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingRight: "10px",
      },
      title: {
        color: isGroomsMan
          ? localColors.fontColorGroomsMan
          : localColors.fontColorBridesMaid,
        textAlign: "center",
        paddingTop: "80px",
      },
      body: {
        paddingTop: "5px",
        color: isGroomsMan
          ? localColors.fontColorGroomsMan
          : localColors.fontColorBridesMaid,
      },
    };
  });

export default function GroomsPersonMobile({ src, title, body, isGroomsMan }) {
  const styleClass = rawStyle(isGroomsMan)();
  return (
    <div className={styleClass.box}>
      <img className={styleClass.img} src={src} />
      <div className={styleClass.textBox}>
        <div className={styleClass.textContainer}>
          <Typography variant="h5" className={styleClass.title}>{title}</Typography>
          <p className={styleClass.body}>{body}</p>
        </div>
      </div>
    </div>
  );
}
