import React from "react";

export default class RSVPForm extends React.Component {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <iframe
          style={{ width: "100%" }}
          src="https://docs.google.com/forms/d/e/1FAIpQLSfEpgnlRqiuTro4L6iT3m5kA80B2tj9hmSs2bJpB-BLxCCM0g/viewform?embedded=true"
          height="800"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    );
  }
}
