import "./App.css";
import { makeStyles } from "@material-ui/core/styles";
import { pages } from "./components/PageContent";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Colors from "./util/Colors";
import Footer from "./components/Footer";
import PageContent from "./components/PageContent";
import React from "react";
import { getMemorialPhotos } from "./data/DAO";
import Title from "./components/Title";
import Toolbar from "@material-ui/core/Toolbar";
import { Grid } from "@material-ui/core";
import spans from "./util/LocalSpans";
import { Centered } from "./util/Styled";
import Gallery from "./components/Gallery";

function changePage(pageType) {
  window.pageContent.changePage(pageType);
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    backgroundColor: Colors.backgroundToolbar,
    display: "block",
  },
  title: {
    flexGrow: 1,
    padding: "2px",
    color: "white",
  },
}));

/*
Our story
The game

password enabled
their first/last name
update the questions on the rsvp

rsvp in a modal, "exit RSVP" at the bottom
clicking on it will close the modal and show the animation

questions
 - attendence, yn
 - names of attending? 
 - __ of 2 will attend
 http://www.abrideonabudget.com/2016/02/wording-rsvp-card-to-limit-guests.html
 
 only 1 passcode
 48927
 name 
 email 
 number attending
 names of plus 1

 The pacman animation when it is submitted

 Registry
 Donate x amount for this thing
 4 Places for the registry

*/

const Banner = () => {
  return (
    <Centered>
      <div style={{ width: "100%", background: "#F4E7A1" }}>
        <p style={{ color: "black", padding: "20px" }}>
          Thank you to all who were able to make it!
        </p>
      </div>
    </Centered>
  );
};

function WeddingWebsite() {
  const classes = useStyles();
  return (
    <div
      className="App"
      style={{
        minHeight: "100vh",
        position: "relative",
        backgroundColor: "#410f1f",
      }}
    >
      <Title
        title={"Just My Type"}
        subtitle={"Patrick & Elizabith"}
        action={() => changePage(pages.homepage)}
      />
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.toolbar} variant="root">
            <Centered>
              <Grid container spacing={0}>
                {[
                  // {
                  //   name: "Logistics",
                  //   action: () => changePage(pages.logistics),
                  // },
                  {
                    name: "Our Story",
                    action: () => changePage(pages.our_story),
                  },
                  {
                    name: "Wedding Party",
                    action: () => changePage(pages.weddingParty),
                  },
                  { name: "Gallery", action: () => changePage(pages.gallery) },
                  // { name: "RSVP", action: () => changePage(pages.rsvp) },
                  // These ones are disabled for now
                  // {
                  //   name: "Registry",
                  //   action: () => changePage(pages.registry),
                  // },
                  // { name: "Secret", action: () => changePage(pages.secret) },
                ].map((it) => (
                  <Grid
                    item
                    // xs={spans.halfwidth}
                    sm={spans.thirdwidth}
                  // sm={spans.quarterwidth}
                  // md={spans.sixthwidth}
                  >
                    <Button className={classes.title} onClick={it.action}>
                      {it.name}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Centered>
            <Banner />
          </Toolbar>
        </AppBar>
      </div>
      <PageContent />
      <Footer />
    </div>
  );
}

function MemorialWebsite() {
  const classes = useStyles();
  return (
    <div
      className="App"
      style={{
        minHeight: "100vh",
        position: "relative",
        backgroundColor: "#D6EEC4",
      }}
    >
      <Title title={"Dan Beagan"} subtitle={"In Memoriam"} />
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.toolbar} variant="root">
            {/* <Banner /> */}
          </Toolbar>
        </AppBar>
      </div>
      <Gallery photoset={getMemorialPhotos()} />
    </div>
  );
}

function App() {
  return (
    <div>
      <WeddingWebsite />
      {/* <MemorialWebsite /> */}
    </div>
  );
}

export default App;
